import React from 'react'
import SubBanner from '../share/SubBanner'
import AboutComponent from '../share/About/About'
import { AboutStyled } from './Styles'
import Active from '../share/Active/Active'
import background from "../../image/team.png";
import Partner from '../share/Partner/Partner'
import { useSelector } from "react-redux";
import { translate } from "../../i18n";
import { RootState } from "../../store";
const About = () => {
  const { language } = useSelector((state: RootState) => state.lang);
  return (
    <>
    <AboutStyled>
    <SubBanner title={translate('navAbout', language)} child={translate('navAbout', language)}></SubBanner>
      <AboutComponent></AboutComponent>
      
      <Active></Active>
      <Team></Team>
      <Partner></Partner>
      </AboutStyled>
    </>
  )
}
const Team = () => {
  const { language } = useSelector((state: RootState) => state.lang);
  const arrTeam = [{
    img : 'team1',
    name : 'Emma London',
    status : 'Co-Founder'
  },
  {
    img : 'team2',
    name : 'Emma London',
    status : 'Co-Founder'
  },
  {
    img : 'team3',
    name : 'John Smith',
    status : 'CEO'
  },
  {
    img : 'team4',
    name : 'Emma London',
    status : 'Co-Founder'
  },

]
  return (
    <>
    <div className="team"  style={{ backgroundImage: `url(${background})`} }>
      <div className="container">
    <p className="subtitle">{translate('teamSubtitle', language)}</p>
      <h3 className="title">{translate('teamTitle', language)}</h3>
    <div className="team-list">
    {
       arrTeam.map((item) =>{
         return (
           <>
          <TeamItem img={item.img} name={item.name} status={item.status}></TeamItem>
           </>
         );
       })
     }
    </div>

      </div>
    </div>
    </>
  )
}
const TeamItem = (props: { img: string; name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; status: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined }) => {
  
  return(
    <>
    <div className="team-container">
    <div className="team-img"> <img src={require(`../../image/${props.img.toLowerCase()}.png`)} alt =''/></div>
    <p className="team-name">{ props.name}</p>
    <p className="text-orange">{ props.status }</p>
    </div>
    </>
  )
}
export default About