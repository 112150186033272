import styled, { keyframes } from "styled-components";
const textHeading = "3.2rem";
const textNormal = "1.6rem";
const textWhite = "#fff";
const textColor = "#002745";
const colorHover = "#ff9900fa";
export const AboutStyled = styled.div`
  .about {
    padding: 8.5rem 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &-container {
      
      display: flex;
      align-items: center;
    }
    &-subtitle {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 1.8rem ;
    }
    &-content {
      padding-right: 10rem;
      max-width: 45%;
    }
    &-title {
      text-transform: capitalize;
      font-weight: 600;
      line-height: 1.6;
      padding-bottom: 1rem;
    }
    .text-justify {
      text-align: justify;
      font-weight: 700 ;
    }
    .discoverBtn {
      display: inline-block;
      margin-top: 3rem;
      color: ${textWhite};
      padding: 1rem 1.5rem;
      background: ${textColor};
      border-radius: 15px 0px;
      font-size: 1.6rem;
      font-weight: 600;
      :hover {
        color: ${colorHover};
      }
    }
  }
`;
