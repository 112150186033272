import styled, { keyframes } from "styled-components";
const textHeading = "3.2rem";
const textNormal = "1.6rem";
const textWhite = "#fff";
const textColor = "#002745";
const colorHover = "#ff9900fa";
export const NewsStyled = styled.div`
  .container {
    background-color: #f1f3f4;
    margin-top: 6rem;
    margin-bottom: 6rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 12px;
  }
  .news {
    .blog-list {
      padding-top: 5%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 5%;
    }
    .card {
    position: relative;
    
   
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: white;
    text-align: left;
    border: 1px solid #bacdd8;
    width: calc(33.33% - 1rem);
    margin-top: 2%;
    /* border-radius: 12px; */
    :hover {
      /* outline: 10px solid #0AA1DD; */
    }
    img {
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      border-radius: 6px;
      width: 100% ;
      height: 100%;
    }
    

   
    .name {
      font-size: 2rem;
      font-weight: 700;
      color: #000 ;
      margin-top: 1rem;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      /* overflow: hidden; */
      display: -webkit-box;
      color: rgba(255, 255, 255, 0.9) ;
    }
    option {
      border: none;
    }
    /* Style p element */
    p {
      font-size: 16px;
      color: #fff !important;
    }
    p.name {
      color: #49535e;
      height: 7rem;
      overflow: hidden;
     
    }
    .card__details {
      position: absolute;
      bottom: 0;
      width: 100%;
      min-height: 35% ;
      background: linear-gradient(180deg,rgba(0,39,69,0.5) 0%,rgba(0,39,69,0) 100%);
      /* Add space around the details */
      padding: 16px 8px 8px 8px;
    }
  }
  }
`;
