import styled, { keyframes } from "styled-components";
const textHeading = "3.2rem";
const textNormal = "1.6rem";
const textWhite = "#fff";
const textColor = "#002745";
const colorHover = "#ff9900fa";
export const AboutStyled = styled.div`
  .about {
    min-height: 73rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .subtitle {
      text-transform: capitalize;
    }
    &-container {
      margin-top: 5%;
      margin-bottom: 5%;
      display: flex;
      align-items: center;
    }
    &-subtitle {
      font-weight: 600;
      text-transform: capitalize;
    }
    .about-content {
      padding-right: 10rem;
      width: 60%;
    }
    &-title {
      text-transform: capitalize;
      font-weight: 600;
      line-height: 1.6;
      padding-bottom: 1rem;
    }
    .discoverBtn {
      display: inline-block;
      margin-top: 3rem;
      color: ${textWhite};
      padding: 1rem 1.5rem;
      background: ${textColor};
      border-radius: 15px 0px;
      font-size: 1.6rem;
      font-weight: 600;
      :hover {
        color: ${colorHover};
      }
    }
    .color1 {
      color: #f7593d;
    }
    .color2 {
      color: #ff9671;
    }
    .color3 {
      color: #ff6f91;
    }
    .color4 {
      color: #ff6fe8;
    }
  }
  .active-desc {
    padding-top: 1rem;
  }
`;
