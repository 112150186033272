import React from "react";
import { Link } from "react-router-dom";
import { AboutStyled } from "./Styles";
import aboutImage from "../../../image/about.png";
import { useSelector } from "react-redux";
import { translate } from "../../../i18n";
import { RootState } from "../../../store";
function About() {
  const { language } = useSelector((state: RootState) => state.lang);
  return (
    <AboutStyled>
      <div className="about">
        <div className="container about-container">
          <div className="about-content">
            <p className="about-subtitle">{translate('aboutSubtitle1', language)}</p>
            <h2 className="about-title">
            {translate('aboutSubtitle2', language)}
            </h2>
            <p className="text-justify">
            {translate('aboutDesc', language)}
            </p>
            <Link className="discoverBtn" to="">
            {translate('aboutBtn', language)}
            </Link>
          </div>
          <div className="about-image">
            <img src={aboutImage} alt="" />
          </div>
        </div>
      </div>
    </AboutStyled>
  );
}

export default About;
