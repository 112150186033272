import styled, { keyframes } from "styled-components";
const textHeading = "3.2rem";
const textNormal = "1.6rem";
const textWhite = "#fff";
const textColor = "#002745";
const colorHover = "#ff9900fa";
export const PartnerStyled = styled.div`
    .partner{
        padding: 8rem 0;
        &-list{
            margin-top: 5rem;
            display: flex;
            flex-wrap: wrap;
            
            justify-content:space-evenly;
        }
        &-img{
            padding: 1% 0;
        }
       
        &-img:hover{
            outline:1.5px solid rgba(0, 113, 188, 0.3);
            border-radius: 10px 0px;
           
        }
        

    }
`;
