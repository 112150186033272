import React from "react";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesRight,
  faLocationDot,
  faEnvelopeOpenText,
  faSquarePhoneFlip,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitterSquare,
  faTelegram,
  faYoutubeSquare,
} from "@fortawesome/free-brands-svg-icons";
import { useSelector } from "react-redux";
import background from "../../image/footer-circle.png";
import { HashLink } from "react-router-hash-link";
import { translate } from "../../i18n";
import { RootState } from "../../store";
function Footer() {
  const { language } = useSelector((state: RootState) => state.lang);
  return (
    <footer
      className="footer"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="footer-container container">
        <div className="footer-col ">
          <h3 className="footer-title underline">
            {translate("footerAboutTitle", language)}
          </h3>
          <p className="footer-desc text-justify">
            {translate("footerAboutDesc", language)}
          </p>
          <div className="footer-social-list">
            <FontAwesomeIcon icon={faFacebook} />
            <FontAwesomeIcon icon={faTwitterSquare} />
            <FontAwesomeIcon icon={faTelegram} />
            <FontAwesomeIcon icon={faYoutubeSquare} />
          </div>
        </div>
        <div className="footer-col hidden-table">
          <h3 className="footer-title underline ">
            {translate("footerLinkTitle", language)}
          </h3>
          <div className="footer-link">
            <NavLink to="/about">
              <FontAwesomeIcon icon={faAnglesRight} />
              {translate("navAbout", language)}
            </NavLink>
          </div>
          <div className="footer-link">
            <HashLink smooth to="/#service">
              <FontAwesomeIcon icon={faAnglesRight} />   {translate("navService", language)}
            </HashLink>
          </div>
          <div className="footer-link">
            <NavLink to="/contact">
              <FontAwesomeIcon icon={faAnglesRight} />  {translate("navContact", language)}
            </NavLink>
          </div>
          <div className="footer-link">
            <NavLink to="/news">
              <FontAwesomeIcon icon={faAnglesRight} />{translate("navNews", language)}
            </NavLink>
          </div>
        </div>
        {/* <div className="footer-col hidden-table">
          <h3 className="footer-title underline">{translate("footerHelpTitle", language)}</h3>
          <div className="footer-link">
            <Link to="#">
              <FontAwesomeIcon icon={faAnglesRight} />{translate("footerHelpTitle1", language)}
            </Link>
          </div>
          <div className="footer-link">
            <Link to="#">
              <FontAwesomeIcon icon={faAnglesRight} />{translate("footerHelpTitle2", language)}
            </Link>
          </div>
          <div className="footer-link">
            <Link to="#">
              <FontAwesomeIcon icon={faAnglesRight} />{translate("footerHelpTitle3", language)}
            </Link>
          </div>
          <div className="footer-link">
            <Link to="#">
              <FontAwesomeIcon icon={faAnglesRight} />{translate("footerHelpTitle4", language)}
            </Link>
          </div>
        </div> */}
        <div className="footer-col">
          <h3 className="footer-title underline">{translate("footerContactTitle", language)}</h3>
          <p className="footer-desc">
            <FontAwesomeIcon icon={faLocationDot} />
            {translate("footerAddress", language)}
          </p>
          <p className="footer-desc">
            <FontAwesomeIcon icon={faEnvelopeOpenText} />{" "}
            metaholding.info@gmail.com
          </p>
          <p className="footer-desc">
            <FontAwesomeIcon icon={faSquarePhoneFlip} /> (+84) 98 20471231
          </p>
        </div>
      </div>
      <p className="footer-copy-right">
      {translate("footerCopyright", language)}
      </p>
    </footer>
  );
}

export default Footer;
