import styled, { keyframes } from "styled-components";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
const textHeading = "3.2rem";
const textNormal = "1.6rem";
const textWhite = "#fff";
const textColor = "#002745";
const colorHover = "#ff9900fa";
export const BannerMainStyled = styled.div`

  .banner {
    /* background-image: url("./image/background/banner-main.png"); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 80vh;
    text-align: center;
    
    width: 100%;
    .title {
      color: ${textWhite};
      text-transform: uppercase;
      padding: 19rem 0 3rem;
      font-weight: 700;
    }
    .desc {
      color: ${textWhite};
      line-height: 1.6;
    }
    .discoverBtn {
      display: inline-block;
      margin-top: 3rem;
      color: #0071bc;
      padding: 1rem 1.5rem;
      background: ${textWhite};
      border-radius: 15px 0px;
      font-size: 1.6rem;
      font-weight: 600;
      :hover {
        color: ${colorHover};
      }
    }
  }
`;
export const NoticeHomeStyled = styled.div`
  .notice-home {
    position: absolute;
    transform: translateY(-50%);
    z-index: 10;
    left: 0;
    right: 0;
  }
`;
export const ServiceStyled = styled.div`
  .card {
    position: relative;
    height: 25rem ;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: white;
    text-align: left;
    width: calc(33.33% - 1rem);
    margin-top: 2%;
    :hover {
      /* outline: 10px solid #0AA1DD; */
    }
    img {
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      width: 100% ;
      height: 100% ;
    }
    .name {
      font-size: 2rem;
      font-weight: 700;
      color: #000 ;
      margin-top: 1rem;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      color: rgba(255, 255, 255, 0.9) ;
    }
    option {
      border: none;
    }
    p {
      font-size: 16px;
      color: #fff !important;
    }
    p.name {
      color: #49535e;
      height: 7rem;
      overflow: hidden;
    }
    .card__details {
      position: absolute;
      bottom: 0;
      width: 100%;
      min-height: 35% ;
      background: linear-gradient(180deg,rgba(0,39,69,0.5) 0%,rgba(0,39,69,0) 100%);
      padding: 16px 8px 8px 8px;
    }
  }
  .service {
    text-align: center;
    background: #024876;
    padding-top: 8.5rem;
    padding-bottom: 8.5rem;
    .blog-subtitle{
      color: #fff !important; 
      font-size: 1.8rem !important;
      text-transform: uppercase !important;
    }
    &-title {
      color: ${textWhite};
      text-transform: uppercase;
      padding-top: 2rem;
      font-weight: 600;
    }
    &-subtitle {

      color: #0071bc;
      font-weight : 600 ;
      font-size: 2rem;
      text-transform: uppercase;
      padding: 1rem 0;
    }
    .service-reletive{
        position: absolute;
        bottom:0;
        font-weight: 700 ;
        padding: 5% 0 8% 0;
        color:rgba(255, 255, 255, 0.8);
        width: 100% ;
        background: linear-gradient(180deg, rgba(0, 39, 69, 0.5) 0%, rgba(0, 39, 69, 0) 100%);
      }
    &-list {
      /* padding-top: 12rem; */
      display: flex;
      justify-content: space-between ;
      flex-wrap: wrap;
    }
    
    &-item {
      margin-top: 5% ;
      display: inline-block;
      width: calc(50% - 0.8rem);
      border: 
      2px solid rgba(255, 255, 255, 0.1);
      border-radius: 0px 20px;
      border-radius: 1.5rem 0;
      transition: all 0.2s ease-in-out;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      position:relative;
      &:nth-child(2n){
        border-radius: 20px  0px;
      }
      &:nth-child(2n+1){
        border-radius: 0px 20px;
      }
      :hover,
      :focus {
        transform: translateY(-5%);
      }
    }
    &-logo {
      width: 6rem;
      height: 6rem;
      margin: 0 auto;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      position: relative;
    }
    &-img {
      margin: 0 auto;
      transform: translateY(45%);
      width: 3rem;
      position: absolute;
      top: 50%;
      left: 50%;
      
      transform: translate(-50%, -50%);
    }

    p {
      color: #000;
      font-weight : 600 ;
      line-height: 1.4;
    }
    .blog-list {
      padding-top: 5%;
      display:  flex;
      justify-content:space-between ;
    }
    .blog-item {
      overflow: hidden;
      height: 43.8rem;
      width: calc(33.33% - 2.13333rem);
      /* border: 1px solid #BFDEF5; */
      border-radius: 15px 0;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .blog-pre {
      height: 10rem;
      background: linear-gradient(
        0deg,
        rgba(50, 49, 49, 0.7),
        rgba(50, 49, 49, 0.7)
      );
      border-radius: 0 0 15px 0;
      padding: 1rem 2rem;
      /* transition: all 0.2s ease-in-out; */
      p {
        color: #fff;
        font-size: 1.8rem;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      P:last-child {
        border-radius: 0 0 15px 0;
      }
    }
    .blog-link {
      text-align: left;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column-reverse;
      background-repeat: no-repeat;
      background-size: cover;
      /* background-size: 100% ; */
      background-position: center;
      border-radius: 15px 0 15px 0;
      transition: all 0.5s ease-in-out;
      :hover {
        transform: scale(1.05);
        /* background-color: #fff; */
        .blog-pre {
        }
      }
      p {
      }
      p:last-child {
        /* white-space: nowrap; */
        overflow: hidden;
        text-overflow: hidden;
      }
    }
    p.date {
      font-size: 1.3rem;
    }
  }
`;
