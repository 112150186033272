import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import { Helmet } from 'react-helmet';

ReactDOM.render(
  <React.Suspense fallback={<>Loading...</>}>
<Provider store={store}>
<Helmet>
        <title>Công ty cổ phần Meta Holding | Meta Holding</title>
        <meta
          property="og:description"
          content="Meta Holding quỹ đầu tư đầu tiên đồng hành cùng các doanh nghiệp start up trên phát triển và gặt hái thành công"
        />
        <meta property="og:url" content="https://metaholding.info/" />
        <meta property="og:title" content="Công ty cổ phần Meta Holding" />
        <meta property="og:type" content="article" />
        <meta
          property="og:image"
          content="http://i.ibb.co/CwkCXRK/preview-bg.jpg"
        />
        <meta
          property="og:image:secure_url"
          content="https://i.ibb.co/CwkCXRK/preview-bg.jpg"
        />
        <meta name="title" content="Công ty cổ phần Meta Holding" />
        <meta name="description" content="Meta Holding quỹ đầu tư đầu tiên đồng hành cùng các doanh nghiệp start up trên phát triển và gặt hái thành công" />
        <meta name = "robots" content = "index, follow" />

      </Helmet>
    <App/>
    </Provider>
    </React.Suspense>,
 
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
