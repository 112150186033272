import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import blogApi from '../../api/blogApi';
import { DetailStyled } from './Styles';
import ReactMarkdown from "react-markdown";
import SubBanner from '../share/SubBanner';
import { useSelector } from "react-redux";
import { translate } from "../../i18n";
import { RootState } from "../../store";
import { Helmet } from 'react-helmet';

// import remarkGfm from 'remark-gfm'
function Detail() {
    let { id } = useParams();
    const [title , setTitle] = useState('');
    const [content , setContent] = useState('');
  useEffect(() =>{
    const fetchBlogList = async () =>{
      try {
        const response = await blogApi.getDetail(id+"");
        setTitle(response.data.attributes.Title);
        setContent(response.data.attributes.Content);
        console.log(title);
      }catch (error){
        console.log(error);
      }
    }
    fetchBlogList();
    console.log(title);
  },[]);
  const { language } = useSelector((state: RootState) => state.lang);
  return (
      <>
      
      <DetailStyled>
      <SubBanner title={title} child={translate('navNews', language)}></SubBanner>
      <div className="detail">
          <div className="container">
              
              <ReactMarkdown  children={content} />
          </div>
      </div>
       </DetailStyled>
      </>
  )
}

export default Detail