import { createGlobalStyle } from "styled-components";
const textHeading = "3.2rem";
const textNormal = "1.6rem";
const textWhite = "#fff";
const textColor = "#002745";
export default createGlobalStyle`
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }
  body {
    line-height: 1;
    font-family: 'Nunito', sans-serif;
    
  }
  ol,
  ul {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none;
  }
  :focus-visible {
    outline: none !important;
}
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  *{
    font-family: 'Nunito', sans-serif;
  }
  html {
    font-size: 62.5%;
    scroll-behavior: smooth;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: ${textHeading};
    color: ${textColor};
  }

  p {
    font-size: ${textNormal};
    color: ${textColor};
    line-height: 1.4;
  }

  // box-sizing
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  .text-orange{
    color: #FB8500;
  }
  .text-white{
    color: #fff;
  }
  img {
    max-width: 100%;
    display: block;
  }
  .container {
  max-width: 1170px;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: 0 auto;
  }
  #root{
    position: relative;
  }
  .menu{
    position:fixed;
    top: 0;
    background-color: #fff;
    width: 100%;
    z-index: 2;
  }
  .main{
    margin-top: 8rem;
    overflow: hidden;
  }
  a{
    text-decoration: none;
  }
  .subtitle{
    font-size: 1.8rem;
    font-weight: 600;
    margin-top: 1rem;
  }
  .upper-case{
    text-transform: uppercase;
  }
  .menu{
    &-container{
      display:flex;
      justify-content: space-between;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      align-items: center;
    }
    &-left{
      width: 25%;
    }
    &-item{
      padding: 0 2.5rem;
    }
    &-link{
      font-size: 1.6rem;
      font-weight: 600;
      color: ${textColor};
    }
    &-right{
      display: flex;
      align-items: center;
    }
    &-language{
      border: none;
      font-weight: 700;
      font-size: 1.6rem;
    }
  }
  
  .footer{
    background-color: #033154;
    background-repeat: no-repeat;
    min-height: 50rem;
    background-position: bottom;
    padding:10rem 0 20rem 0;
    position: relative;
    &-container{
      display:flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    &-col{
        width: 22%;
        margin-top: 10%;
    }
    &-title{
      display: inline-block;
      padding-bottom: 1rem;
      margin-bottom: 3.5rem;
      font-size: 2rem;
      text-transform: uppercase;
      font-weight: 500;
      color: ${textWhite};
    }
    &-social-list{
      svg{
        font-size: 2.8rem;
        margin-right: 1.8rem;
        color: ${textWhite};
      }
    }
    .text-justify{
      text-align: justify;
    }
    &-link{
      margin-bottom: 2rem;
      font-size:1.6rem;
      color: ${textWhite};
      a{
        color: ${textWhite};
        :hover{
        color:#0099FF;
      }
      }
      
    }
    &-desc{
      margin-bottom: 1.5rem;
      font-size:1.6rem;
      color: ${textWhite};
    }
    &-copy-right{
      position: absolute;
      bottom: 10%;
      left: 0;
      right: 0;
      text-align:center;
      color: ${textWhite};
    }
  }
  .active{
    color: #1ea1f3;
    font-weight : 800 ;
  }
  .underline{
    border-bottom: 0.4rem solid #0071BC;
    border-radius: 4px 4px;
  }
  .mobile-menu-icon{
    display: none;
    border: none;
    background: transparent;
  }
  @media (max-width: 1024px) {
   .card button {
    margin-bottom: 50px !important;
  }
    
  }
 
  @media (max-width: 768px) {
    .menu-link {font-size: 1.5rem !important}
    .notice-item { padding: 4.5rem 3rem !important; min-width: 18rem !important; }
    .notice-container { width: 100% !important}
    .hidden-table { display: none !important}
    .service-list {
      flex-wrap: wrap;
      padding-top: 6rem !important;
      .about-title {line-height: 1.4 !important}
     

}
.footer-col{width:40% !important;}
.mobile-menu-icon{ 
  display: block !important;
  font-size: 3rem;
}

    .service-item {
      width: 34% !important;
    margin: 2rem 0 !important;
    padding: 8% 1% !important;
    }
      .about-item{
        h4{
          margin-bottom: 1rem;
          line-height: 1.4;
        }
      }
      .hidden-table{
        display: none !important;
      }
      .footer-social-list svg {
        margin-right: 0.8rem;
      }
      .menu-right{display: none}
      .MuiSvgIcon-root{
        fill: currentColor;
      width: 3.5rem;
      height: 3.5rem;
      display: inline-block;
      font-size: 1.5rem;
      transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      flex-shrink: 0;
      user-select: none;
      }
      .menu-left {
    width: 25%;
}

    .menu{
       position: relative;
     }
     .menu-mobie{
       position: absolute;
       top: 100%;
       width: 100%;
       left: 0;
       right: 0;
       text-align: center;
       
       .menu-item{
         background: rgba(255, 255, 255, 0.8);
         width: 100%;
         padding: 1rem 0;
         :hover{
           background-color: #fff;
         }
       }
     }
     .about-content {
    padding-right: 4rem !important;
     }
     .service-list {
      justify-content: space-around !important;
    }
    .blog-item {
    height: 27.8rem !important;
    }
    .card{
     
     button {
      /* margin-bottom: 50px !important; */
    }
    }
    .address-item {
      width: 32% !important;
    }
  }
  
  @media (max-width: 425px) {
    .menu-left {
    width: 45%;
  }
    .menu-link {font-size: 1.5rem !important;}
    .hidden-mobile { display: none !important;}
    .notice-container {    flex-wrap: wrap !important;}
    .address-item {width: 100%!important;}
    .banner {
    
      min-height: 50rem !important;
    }
    .banner .title {
    padding: 10rem 0 3rem !important;
    font-size: 2.6rem;
    }
    .notice{
      display: none;
    }
    .about-content {
      max-width: 100% !important;
      padding-right: 0 !important;
    }
    .about-title {
    font-size: 2.6rem;
    }
    .about-container {
      margin-top: 15% !important;
    margin-bottom: 15% !important;
      flex-wrap: wrap !important;}
    .service-list {

    }
    .service-item {
      border-radius: 1rem !important;
      
    }
    .service-desc{
      display: none;
    }
    .active-desc{
      display: none;
    }
    .blog-list{
      flex-direction: column;
    align-items: center;
    }
    .blog-item{
      
    }
    .card {
    margin-top: 50px;
}
.footer-col {
    width: 100% !important;
}
.card button {
    margin-bottom: 10px !important;
}
.notice-about {
  display: none;
}
 .team {
    min-height: 46.5rem !important;
}
.team-list{
  flex-wrap: wrap;
}
.team-container{
  width: 39% !important;
  margin: 0 2rem !important;
}
  }
  `;
