import React from "react";
import { Link } from "react-router-dom";
import { SubBannerStyled } from "./Styles";
import { useLocation } from "react-router-dom";
import background from "../../../image/sub-banner.png";
import { translate } from '../../../i18n';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';
const SubBanner = (props: { title: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; child: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }) => {
  const location = useLocation();
  const { language } = useSelector((state: RootState) => state.lang);
  
  return (
    <>
      <SubBannerStyled>
        <div className="subbanner" style={{ backgroundImage: `url(${background})` }}>
          <div className="subbanner-container">
            <h2 className="subbanner-title">{props.title}</h2>
            <p className="subbanner-path-tree">
              <Link className="subbanner-path-root" to="/">{translate('navHome', language)} |</Link>
              <span className="subbanner-path-child">
              <Link to={location.pathname}> {props.child} </Link>
              </span>
              
            </p>
          </div>
        </div>
      </SubBannerStyled>
    </>
  );
};

export default SubBanner;
