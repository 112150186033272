import axiosClient from "./axiosClient";
class BlogApi {
  getAll = () => {
    const url = "/api/posts?populate=%2A&sort[0]=Date:desc";
    return axiosClient.get(url);
  };
  getTop3 = () => {
    const url =
      "/api/posts?populate=%2A&sort[0]=Date:desc&pagination[start]=0&pagination[limit]=3";
    return axiosClient.get(url);
  };
  getDetail = (id) => {
    const url = "/api/posts/" + id + "";
    return axiosClient.get(url, id);
  };
}

const blogApi = new BlogApi();
export default blogApi;
