import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AboutPage from '../page/AboutPage';
import ContactPage from '../page/ContactPage';
import HomePage from "../page/HomePage"
import Layout from "../page/layout/Layout"
import NewsPage from "../page/NewsPage"
import DetailPage from "../page/DetailPage"
import { Helmet } from 'react-helmet';


function App() {
  return (
    <Router>
      <Helmet>
        <title>Công ty cổ phần Meta Holding | Meta Holding</title>
        <meta
          property="og:description"
          content="Meta Holding quỹ đầu tư đầu tiên đồng hành cùng các doanh nghiệp start up trên phát triển và gặt hái thành công"
        />
        <meta property="og:url" content="https://metaholding.info/" />
        <meta property="og:title" content="Công ty cổ phần Meta Holding" />
        <meta property="og:type" content="article" />
        <meta
          property="og:image"
          content="http://i.ibb.co/CwkCXRK/preview-bg.jpg"
        />
        <meta
          property="og:image:secure_url"
          content="https://i.ibb.co/CwkCXRK/preview-bg.jpg"
        />
        {/* <meta
          property="og:image"
          content="http://i.ibb.co/CwkCXRK/preview-bg.jpg"
        />
        <meta
          property="og:description"
          content="Meta Holding quỹ đầu tư đầu tiên đồng hành cùng các doanh nghiệp start up trên phát triển và gặt hái thành công"
        /> */}
        {/* <meta name="title" content={title} /> */}
        <meta name="description" content="Meta Holding quỹ đầu tư đầu tiên đồng hành cùng các doanh nghiệp start up trên phát triển và gặt hái thành công"/>
        <meta name = "robots" content = "index, follow"  />

      </Helmet>
        <Layout>
          <Routes>
            <Route path="/" element={<HomePage/>} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/news" element={<NewsPage/>}/>
            <Route path="/news/:id" element={<DetailPage/>}/>
          </Routes>
        </Layout>
      </Router>
  );
}
export default App;
