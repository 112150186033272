import React, { useEffect, useState } from "react";
import { Link, To } from "react-router-dom";
import Notice from "./../share/Notice/Notice";
import About from "./../share/About/About";

import { BannerMainStyled, NoticeHomeStyled, ServiceStyled } from "./Styles";
import Active from "../share/Active/Active";
import Partner from "../share/Partner/Partner";
import blogApi from "../../api/blogApi";
import background from "../../image/banner-main.png";
import { useSelector } from "react-redux";
import { translate } from "../../i18n";
import { RootState } from "../../store";
import Slider from "react-slick";
import bgService from "../../image/bg_service.png";
import { Helmet } from "react-helmet";
function HomePage() {
  return (
    <>
      <Helmet>
        <title>Công ty cổ phần Meta Holding | Meta Holding</title>
        <meta
          property="og:description"
          content="Meta Holding quỹ đầu tư đầu tiên đồng hành cùng các doanh nghiệp start up trên phát triển và gặt hái thành công"
        />
        <meta property="og:url" content="https://metaholding.info/" />
        <meta property="og:title" content="Công ty cổ phần Meta Holding" />
        <meta property="og:type" content="article" />
        <meta
          property="og:image"
          content="http://i.ibb.co/CwkCXRK/preview-bg.jpg"
        />
        <meta
          property="og:image:secure_url"
          content="https://i.ibb.co/CwkCXRK/preview-bg.jpg"
        />
        <meta name="title" content="Công ty cổ phần Meta Holding" />
        <meta name="description" content="Meta Holding quỹ đầu tư đầu tiên đồng hành cùng các doanh nghiệp start up trên phát triển và gặt hái thành công" />
        <meta name = "robots" content = "index, follow" />

      </Helmet>
      <Banner></Banner>
      <About></About>
      <Service></Service>
      <Active></Active>
      <Blog></Blog>

      <Partner></Partner>
    </>
  );
}
const Blog = () => {
  const [blogList, setBlogList] = useState<any[]>([]);
  useEffect(() => {
    const fetchBlogList = async () => {
      try {
        const response = await blogApi.getTop3();
        setBlogList(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchBlogList();
  }, []);
  const formatDate = (dateStr: {
    split: (arg0: string) => [any, any, any];
  }) => {
    const [year, month, day] = dateStr.split("-");
    let newDate = `${day}/${month}/ ${year}`;
    return newDate;
  };
  const { language } = useSelector((state: RootState) => state.lang);
  return (
    <ServiceStyled>
      <div className="service" style={{ backgroundImage: `url(${bgService})` }}>
        <div className="container service-container">
          <p className="service-subtitle blog-subtitle">
            {translate("blogSubtitle", language)}
          </p>
          <h3 className="service-title">{translate("blogTitle", language)}</h3>
          <div className="blog-list">
            {blogList.map((item, key): JSX.Element => {
              let date = formatDate(item.attributes.Date);
              const api_url = process.env.REACT_APP_API_URL;
              let newImage = item.attributes.Image.data.attributes.url + "";
              let link = "news/" + item.id;
              return (
                <Link to={link} key={item.attributes.Slug} className="card">
                  <img src={`${api_url}${newImage}`} alt="NEWS" />
                  <div className="card__details">
                    <p className="">{date}</p>
                    <h3 className="name">{item.attributes.PostSeo.SeoTitle}</h3>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </ServiceStyled>
  );
};

const Service = () => {
  const { language } = useSelector((state: RootState) => state.lang);
  const arrService = [
    {
      img: "real_estate",
      name: "| Đầu tư bất động sản",
      href: "https://befurni.com/",
    },
    {
      img: "stock",
      name: "| Đầu tư chứng khoán",
      href: "https://www.behomes.vn/",
    },
    {
      img: "startup",
      name: "| Đầu tư vàO các start-up",
      href: "https://bestay.vn/",
    },
    {
      img: "ma",
      name: "| Thực hiện M&A, tái cơ cấu doanh nghiệp",
      href: "https://belab.fun/",
    },
  ];
  return (
    <ServiceStyled>
      <div
        id="service"
        className="service"
        style={{ backgroundImage: `url(${bgService})` }}
      >
        <div className="container service-container">
          <p className="service-subtitle blog-subtitle">
            {translate("serviceSubtitle", language)}
          </p>
          <h3 className="service-title">
            {translate("serviceTitle", language)}
          </h3>
          <div className="service-list">
            {arrService.map((item) => {
              return (
                <ServiceItems
                  href={item.href}
                  key={item.name}
                  img={item.img}
                  name={item.name}
                ></ServiceItems>
              );
            })}
          </div>
        </div>
      </div>
    </ServiceStyled>
  );
};
const ServiceItems = (props: {
  img: string;
  href: string | undefined;
  name:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}) => {
  const [imgSrc, setImgSrc] = useState(
    require(`../../image/${props.img.toLowerCase()}.png`)
  );
  return (
    <div className="service-item">
      <a href={props.href} target="_blank">
        <img
          src={require(`../../image/${props.img.toLowerCase()}.png`)}
          alt=""
        />
        <h3 className="service-subtitle service-reletive">{props.name}</h3>
      </a>
    </div>
  );
};
const Banner = () => {
  const { language } = useSelector((state: RootState) => state.lang);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: "linear",
  };
  return (
    <BannerMainStyled>
      <Slider {...settings}>
        <div>
          <div
            className="banner"
            style={{ backgroundImage: `url(${background})` }}
          ></div>
        </div>
        <div>
          <div
            className="banner"
            style={{ backgroundImage: `url(${background})` }}
          ></div>
        </div>
        <div>
          <div
            className="banner"
            style={{ backgroundImage: `url(${background})` }}
          ></div>
        </div>
        <div>
          <div
            className="banner"
            style={{ backgroundImage: `url(${background})` }}
          ></div>
        </div>
        <div>
          <div
            className="banner"
            style={{ backgroundImage: `url(${background})` }}
          ></div>
        </div>
        <div>
          <div
            className="banner"
            style={{ backgroundImage: `url(${background})` }}
          ></div>
        </div>
      </Slider>
    </BannerMainStyled>
  );
};

export default HomePage;
