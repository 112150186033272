import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import blogApi from "../../api/blogApi";
import SubBanner from "../share/SubBanner";
import { NewsStyled } from "./Styles";
import { useSelector } from "react-redux";
import { translate } from "../../i18n";
import { RootState } from "../../store";
function News() {
  const [blogList, setBlogList] = useState<any[]>([]);
  useEffect(() => {
    const fetchBlogList = async () => {
      try {
        const response = await blogApi.getAll();
        setBlogList(response.data);
        console.log(blogList);
      } catch (error) {
        console.log(error);
      }
    };
    fetchBlogList();
  }, []);
  const formatDate = (dateStr: {
    split: (arg0: string) => [any, any, any];
  }) => {
    const [year, month, day] = dateStr.split("-");
    let newDate = `${day}/${month}/${year}`;
    return newDate;
  };
  const { language } = useSelector((state: RootState) => state.lang);
  return (
    <NewsStyled>
                <SubBanner title={translate('navNews', language)} child={translate('navNews', language)}></SubBanner>
      <div className="news">
        <div className="container">
          <div className="blog-list">
            {blogList.map((item): JSX.Element => {
              let date = formatDate(item.attributes.Date);
              const api_url = process.env.REACT_APP_API_URL;
              console.log(item.attributes.Image.data.attributes.url);
              console.log(api_url);
              let newImage = item.attributes.Image.data.attributes.url + "";
              let link = "/news/" + item.id;
              return (
                <Link  to={link} key={item.attributes.Slug} className="card">
                  <img src={`${api_url}${newImage}`} alt="NEWS" />
                  <div className="card__details">
                    <p className="">
                      {date}
                    </p>
                    <Link to={link} className="name">{item.attributes.PostSeo.SeoTitle}</Link>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </NewsStyled>
  );
}

export default News;
