import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../image/logo_holding.png";
import { HashLink } from "react-router-hash-link";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { setLanguage } from "../../store/actions/langActions";
import { translate } from "../../i18n";
import { RootState } from "../../store";
import viLang from "../../image/vn.gif";
import enLang from "../../image/us.gif";
function Navbar() {
  const { language } = useSelector((state: RootState) => state.lang);
  const [isMobile, setIsMobile] = useState(false);
  const dispatch = useDispatch();
  const chooseLanguageHandler = (value: string) => {
    dispatch(setLanguage(value));
    setLang(lang == 'VI' ? 'EN' : 'VI');
  };
  const [lang, setLang] = useState('VI');
  return (
    <nav className="menu">
      <div className="menu-container container">
         <button
          onClick={() => setIsMobile(!isMobile)}
          className="mobile-menu-icon"
        >
          {isMobile ? (
            <FontAwesomeIcon icon={faXmark} />
          ) : (
            <FontAwesomeIcon icon={faBars} />
          )}
        </button>
        <div className="menu-left">
          
          <div className="menu-logo">
            <NavLink to="/">
              <img src={logo} alt="logo" />
            </NavLink>
          </div>
        </div>
        <div className={isMobile ? "menu-mobie" : "menu-right"}>
          <div className="menu-item">
            <NavLink
              className={({ isActive }) =>
                isActive ? "active menu-link" : "menu-link"
              }
              to="/"
            >
              {translate("navHome", language)}
            </NavLink>
          </div>
          <div className="menu-item">
            <NavLink className="menu-link" to="/about">
              {translate("navAbout", language)}
            </NavLink>
          </div>
          <div className="menu-item ">
            <HashLink smooth className="menu-link" to="/#service">
              {translate("navService", language)}
            </HashLink>
          </div>
          <div className="menu-item">
            <NavLink className="menu-link" to="/news">
              {translate("navNews", language)}
            </NavLink>
          </div>
          <div className="menu-item">
            <NavLink className="menu-link" to="/contact">
              {translate("navContact", language)}
            </NavLink>
          </div>
        </div>
        <div className="lang">
        {lang == "VI" ? (<img onClick={() => chooseLanguageHandler('EN')} src={enLang} alt="" />) : (<img onClick={() => chooseLanguageHandler('VI')} src={viLang} alt="" />)}

        </div>
       
            {/* <option onClick={() => chooseLanguageHandler('VI')}>VI</option>
  <option onClick={() => chooseLanguageHandler('EN')}>EN</option> */}
  

       
      </div>
    </nav>
  );
}

export default Navbar;
