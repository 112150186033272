import styled, { keyframes } from "styled-components";
const textHeading = "3.2rem";
const textNormal = "1.6rem";
const textWhite = "#fff";
const textColor = "#002745";
const colorHover = "#ff9900fa";
export const SubBannerStyled = styled.div`
    .subbanner {
        text-align: center;
        min-height:23rem;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
    justify-content: center;
    flex-direction: column;
    &-title{
        text-transform: uppercase;
        color: #fff;
        font-size: 2.5rem;
    }
    &-path-tree{
        padding: 1rem 0;
    }
    &-path-root{
        color: ${textWhite};
    }
    &-path-child{
        a{
           
            color: #FB8500;
        }
        
    }
    }
`;
