
import styled, { keyframes } from "styled-components";
const textHeading = "3.2rem";
const textNormal = "1.6rem";
const textWhite = "#fff";
export const DetailStyled = styled.div`
    .detail{
        padding:  6rem 0;
        .title {
            text-align: center;
        }
        
        p{
         text-align: left;
         line-height:1.5;   
        }
    }
`