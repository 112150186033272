import React from "react";
import SubBanner from "../share/SubBanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faSquarePhoneFlip } from "@fortawesome/free-solid-svg-icons";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import { AddressStyled, FormStyled, MapStyled } from "./Styles";
import emailjs from "emailjs-com";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { translate } from "../../i18n";
import { RootState } from "../../store";
import { Helmet } from 'react-helmet';
const ContactPage = () => {
  const { language } = useSelector((state: RootState) => state.lang);
  return (
    <>
    
      <SubBanner title={translate('navContact', language)} child={translate('navContact', language)}></SubBanner>
      <Address></Address>
      <FormContact></FormContact>
      <Map></Map>
    </>
  );
};
const Address = () => {
  const { language } = useSelector((state: RootState) => state.lang);
  return (
    <>

      <AddressStyled>
        <div className="address">
          <div className="container">
            <h3 className="subtitle upper-case">{translate('addressSubtitle', language)}</h3>
            <p className="description">
            {translate('addressDesc', language)}
            </p>
            <div className="address-list">
              <div className="address-item">
                <div className="address-icon">
                  <FontAwesomeIcon icon={faTelegram} />
                </div>
                <h3 className="subtitle">{translate('addressLocation', language)}</h3>
                <p className="description">
                {translate('addressLocationDesc', language)}
                </p>
              </div>
              <div className="address-item">
                <div className="address-icon">
                  <FontAwesomeIcon icon={faSquarePhoneFlip} />
                </div>
                <h3 className="subtitle">{translate('addressSupport', language)}</h3>
                <p className="description">{translate('addressSupportMobile', language)}</p>
                <p className="description">Email: metaholding.info@gmail.com</p>
              </div>
              <div className="address-item">
                <div className="address-icon">
                  <FontAwesomeIcon icon={faClock} />
                </div>
                <h3 className="subtitle">{translate('addressGetInTouch', language)}</h3>
                <p className="description">
                {translate('addressTime', language)}
                  <br />
                  (GMT +7).
                  <br /> {translate('addressClose', language)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </AddressStyled>
    </>
  );
};
const FormContact = () => {
  const form = React.useRef() as React.MutableRefObject<HTMLFormElement>;
  const sendEmail = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_b0yp3ch",
        "template_p81o33y",
        form.current,
        "RCe8xFmDZbiZRCFBV"
      )
      .then(
        (result) => {
          alert("SUCCESS!");
        },
        (error) => {
          alert(error.text);
        }
      );
  };
  const { language } = useSelector((state: RootState) => state.lang);

  return (
    <>
      <FormStyled>
        <div className="form">
          <div className="container">
            <h3 className="subtitle"> {translate('formSubtitle', language)}</h3>
            <p className="desc">
            {translate('formDesc1', language)}
              <br /> {translate('formDesc2', language)}
            </p>
            <form ref={form} onSubmit={sendEmail}>
              {/* <!-- name --> */}
              <div className="form-row">
                <div className="form-group form-width">
                  <input
                    type="name"
                    name="name"
                    className="form-control form-name"
                    id="name"
                    placeholder={translate('formName', language)}
                    required
                  />
                </div>
                {/* <!-- email --> */}
                <div className="form-group form-width">
                  <input
                    type="email"
                    name="email"
                    className="form-control form-email"
                    id="email"
                    placeholder={translate('formAddress', language)}
                    pattern="/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}"
                    required
                  />
                </div>
                {/* <!-- subject --> */}
                <div className="form-group form-width">
                  <input
                    type="text"
                    name="phone"
                    className="form-control form-phone"
                    id="phone"
                    placeholder={translate('formNumber', language)}
                    required
                  />
                </div>
              </div>

              <div className="form-group">
                <textarea
                  className="form-control form-message"
                  id="email_body"
                  name="message"
                  rows={5}
                  placeholder={translate('formMessage', language)}
                  required
                ></textarea>
              </div>
              <div className="form-submit">
                <button type="submit" className="btn btn-primary">
                {translate('formBtn', language)}
                </button>
              </div>
            </form>
          </div>
        </div>
      </FormStyled>
    </>
  );
};
const Map = () => {
  return (
    <MapStyled>
      <div className="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.2445697391863!2d105.78664781524638!3d21.022897986001503!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135abbf04f763e5%3A0xd80c0e28cb831abe!2zVMOyYSBuaMOgIEhMIFRvd2Vy!5e0!3m2!1svi!2s!4v1649320648911!5m2!1svi!2s"
          loading="lazy"
        ></iframe>
      </div>
    </MapStyled>
  );
};
export default ContactPage;
