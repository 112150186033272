import React from "react";
import { PartnerStyled } from "./Styles";
import { translate } from '../../../i18n';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
const Partner = () => {
  const { language } = useSelector((state: RootState) => state.lang);

  const arrPartner = [
    "pn_bechain",
    "pn_befurni",
    "pn_behome",
    "pn_bestay",
  ];
  return (
    <PartnerStyled>
      <div className="partner">
        <div className="container">
          <div className="partner-container">
            <p className="subtitle upper-case">{translate('partnerSubtitle', language)}</p>
            <h4 className="title upper-case">
            {translate('partnerTitle', language)}
            </h4>
            <div className="partner-list">
              {arrPartner.map((partner) => {
                return <PartnerItem key={partner} img={partner}></PartnerItem>;
              })}
            </div>
          </div>
        </div>
      </div>
    </PartnerStyled>
  );
};
const PartnerItem = (props: { img: string }) => {
  return (
    <>
      <div className="partner-img">
        <Link to='#'>
        <img
          src={require(`../../../image/${props.img.toLowerCase()}.png`)}
          alt=""
        />
        </Link>
        
      </div>
    </>
  );
};

export default Partner;
