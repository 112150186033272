import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import BaseStyle from '../../styles/BaseStyle'
function Layout({children}: { children: React.ReactElement }) {
  return (
   
    <>
      <BaseStyle/>
      <Navbar></Navbar>
      <div className="main">{children}</div>
      <Footer></Footer>
    </>
    
  )
}

export default Layout