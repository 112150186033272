import React from "react";
import { Link } from "react-router-dom";
import { AboutStyled } from "./Styles";
import activeImage from "../../../image/active.png";
import { useSelector } from "react-redux";
import { translate } from "../../../i18n";
import { RootState } from "../../../store";
const Active = () => {
  const { language } = useSelector((state: RootState) => state.lang);
  return (
    <AboutStyled>
      <div className="about">
        <div className="container about-container">
          <div className="about-content ">
            <p className="about-subtitle">{ translate('activeSubtitle', language) }</p>
            <h2 className="about-title">
            { translate('activeTitle', language) }
            </h2>
            <div className="about-list">
              <div className="about-item">
                <h4 className="subtitle">
                  <span className="color1">01.</span> { translate('activeTitle1', language) }
                </h4>
                <p className="active-desc">
                { translate('activeContent1', language) }
                </p>
              </div>
              <div className="about-item">
                <h4 className="subtitle">
                  <span className="color2">02.</span> { translate('activeTitle2', language) }
                </h4>
                <p className="active-desc">
                { translate('activeContent2', language) }
                </p>
              </div>
              <div className="about-item">
                <h4 className="subtitle">
                  <span className="color3">03.</span>{ translate('activeTitle3', language) }
                </h4>
                <p className="active-desc">
                { translate('activeContent3', language) }
                </p>
              </div>
              <div className="about-item">
                <h4 className="subtitle">
                  <span className="color4">04.</span>{ translate('activeTitle4', language) }
                </h4>
                <p className="active-desc">
                { translate('activeContent4', language) }
                </p>
              </div>
            </div>
          </div>
          <div className="about-image">
            <img src={activeImage} alt="" />
          </div>
        </div>
      </div>
    </AboutStyled>
  );
};

export default Active;
