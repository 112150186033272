import styled, { keyframes } from "styled-components";
const textHeading = "3.2rem";
const textNormal = "1.6rem";
const textWhite = "#fff";
const textColor = "#002745";
const colorHover = "#ff9900fa";
export const AddressStyled = styled.div`
    .address{
        text-align: center;
        
        &-list{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 6rem;
        }
        &-item{
            background: #FAF9F9;
            width: 28%;
            border-radius: 1.5rem;
            border: 1px solid #D8D8D8;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
            padding: 3rem 0;
            margin: 3rem 0;
        }
        .description{
            padding-top: 1.5rem;
        }
        svg{
            font-size: 3rem;
            color:  #0071BC;
;
        }
    }
`
export const MapStyled = styled.div`
iframe{
    width: 100%;
    height: 600px;
}
`
export const ServiceStyled = styled.div`
    .service{
        text-align: center;
        background: #002745;
        padding-top :8.5rem ;
        padding-bottom: 8.5rem;
        &-title{
            color: ${textWhite};
            text-transform: uppercase;
        }
        &-subtitle {
      font-size: 2rem;
      text-transform: uppercase;
      color: ${textWhite};
      padding: 1rem 0;
    }
    &-list{
        padding-top: 12rem;
        display: flex;
        justify-content:space-between;
    }
    &-item{
        width: 20%;
        border: 1px solid #295ABB;
        padding: 2% 1%;
        border-radius: 2rem 0 ;
        transition: all 0.2s  ease-in-out;
        :hover,:focus{
            transform: translateY(-5%);
            
            border-color: #fff;
            .service-logo{
                background-color:#0071BC;
            }

        }
    }
    &-logo{
        width:6rem;
        height:6rem;
        margin: 0 auto;
        background: #033154;
        border-radius:50%;
    }
    &-img{
        margin: 0 auto;
        transform: translateY(45%);
        width: 3rem;
    height: 3rem;
    }
    }
    p{ 
        color:  #BFDEF5;
        line-height: 1.6;
        padding: 0 1%;
    }
    
`
export const FormStyled = styled.div`
    .form {
        background-color: rgba(128, 198, 227, 0.2);
        padding: 6rem 0;
        .form-name, .form-email, .form-phone,textarea{
           width: 100%;
            margin-top: 2.5rem;
            padding: 1rem 0;
            border: 1px solid #CFCFCF;
            border-radius: 0.5rem;
        }
       
        input, textarea{
            text-indent: 10px;
        }
        .form-row{
            display: flex;
            justify-content: space-between;
        }
        .btn{
            font-size: 1.6rem;
            color: #fff;
            padding: 1.5rem 4.5rem;
            background-color: #0071BC;
            border: none;
            border-radius: 10px 0px;
        }
        .form-width{
            width: 30%;
        }
        .form-submit{
            text-align: center;
            margin-top: 2.5rem;
        }
    }
`